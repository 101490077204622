import React from 'react';
import PropTypes from 'prop-types';
import { StyledBreadcrumbsContainer, StyledLink } from './Breadcrumbs.style';
import { Text } from '@Omegapoint/components';
import { Home, ArrowRight } from '@components/Icon';
import { withTheme } from 'styled-components';

const BreadcrumbsComponent = ({
  theme,
  className,
  path,
  parts,
  hideSecondLast = false,
  ...restProps
}) => {
  const splitPath = path
    .split('/')
    .splice(1)
    .filter((p) => p !== '');

  const splitParts =
    typeof parts === 'undefined' || parts === null
      ? splitPath
      : parts.split('|');

  let currentPath = '';
  return (
    <StyledBreadcrumbsContainer>
      {splitPath.map((p, index) => {
        currentPath += `/${p}`;
        //hide is only to hide the second to last part of the breadcrumb path. Only known usecase right now is to hide the subcategory path
        const hide = index === splitPath.length - 2 && hideSecondLast;
        return (
          <StyledLink key={index} to={currentPath} activeClassName="active">
            {index === 0 ? (
              <Home size={theme.Typography.homeIconSize} />
            ) : !hide ? (
              <Text
                fontSize={theme.Typography.breadcrumbFontSize}
                variant="text"
                uppercase
                light
                color={
                  p === splitPath[splitPath.length - 1]
                    ? theme.Color.primary
                    : theme.Color.text
                }
                bold={
                  p === splitPath[splitPath.length - 1] &&
                  theme.Variables.boldBreadcrumb
                }
                letterSpacing={theme.Typography.breadcrumbLetterSpacing}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: splitParts[index] }}
                ></span>
              </Text>
            ) : null}
            {p !== splitPath[splitPath.length - 1] && !hide && (
              <ArrowRight
                strokeWidth={3}
                size={theme.Typography.breadcrumbArrowIconSize}
              />
            )}
          </StyledLink>
        );
      })}
    </StyledBreadcrumbsContainer>
  );
};

BreadcrumbsComponent.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
  parts: PropTypes.string,
};

export default withTheme(BreadcrumbsComponent);
