import React from 'react';
import PropTypes from 'prop-types';
import {
  BannerContainer,
  LinkSection,
  Ul,
  Li,
  Link,
  LinkText,
} from './PageBanner.style';
import { Col, Row, Container } from '@Omegapoint/components/layout';
import { Text } from '@Omegapoint/components';
import useTranslate from '@hooks/Translation';
import { withTheme } from 'styled-components';

const PageBannerComponent = ({
  links,
  theme,
  isCategoryPicker,
  updateCategory,
  clearFilters,
  activeCategory,
  ...restProps
}) => {
  const { children } = restProps;
  const { translate } = useTranslate();
  return (
    <BannerContainer fluid>
      <Container>
        <Row gutters={theme.Variables.bigGutter}>
          <Col col={12} lg={9}>
            <Col col={12} lg={10}>
              {typeof children === 'string' ? (
                <Text color={theme.Color.white}>{children}</Text>
              ) : (
                children
              )}
            </Col>
          </Col>
          {links && links.length > 0 && (
            <LinkSection col={12} lg={3}>
              {isCategoryPicker ? (
                <>
                  <Text
                    color={theme.Color.white}
                    variant="h4"
                    uppercase
                    dense
                    bold
                  >
                    {translate('News_Archive_Categories')}
                  </Text>
                  <Ul>
                    {links.map((link, i) => (
                      <Li
                        key={i}
                        narrow
                        onClick={() => updateCategory && updateCategory(link)}
                      >
                        <Text
                          color={theme.Color.white}
                          bold={activeCategory === link}
                        >
                          {translate(link)}
                        </Text>
                      </Li>
                    ))}
                    <Li narrow>
                      <Text
                        onClick={() => clearFilters()}
                        color={theme.Color.white}
                      >
                        {translate('All_In_News_Archive')}
                      </Text>
                    </Li>
                  </Ul>
                </>
              ) : (
                <>
                  <Text
                    color={theme.Color.white}
                    variant="h4"
                    uppercase
                    dense
                    bold
                  >
                    {translate('Menu')}
                  </Text>
                  <Ul>
                    {links.map((link, i) => (
                      <Li key={i}>
                        <Link to={link?.Cta?.Url}>
                          <LinkText color={theme.Color.white}>
                            {link?.Cta?.Text}
                          </LinkText>
                        </Link>
                      </Li>
                    ))}
                  </Ul>
                </>
              )}
            </LinkSection>
          )}
        </Row>
      </Container>
    </BannerContainer>
  );
};

PageBannerComponent.propTypes = {
  links: PropTypes.array,
};

export default withTheme(PageBannerComponent);
