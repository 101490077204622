import styled from 'styled-components/macro';
import Link from 'gatsby-link';

export const StyledBreadcrumbsContainer = styled.section`
  display: flex;
  text-align: center;
  flex-wrap: wrap;

  * {
    padding-top: 2px;
  }

  @media ${({ theme }) => theme.Breakpoints.sm} {
    flex-wrap: nowrap;

    * {
      padding-top: 0;
    }
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.Color.text};

  > * {
    margin: 0 5px 0 0;
    > * {
      margin: 0 10px -3px 10px;
    }
  }

  > svg {
    flex-shrink: 0;
  }
`;
