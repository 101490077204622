import styled from 'styled-components/macro';
import { Container, Col } from '@Omegapoint/components/layout';
import GatsbyLink from 'gatsby-link';
import { Text } from '@Omegapoint/components';

export const BannerContainer = styled(Container)`
  background-color: ${({ theme }) => theme.Color.bannerBg};
  padding: ${({ theme }) => theme.Variables.gutter} 0;

  @media ${({ theme }) => theme.Breakpoints.lg} {
    padding: ${({ theme }) => theme.Variables.bigGutter} 0;
  }
`;

export const LinkSection = styled(Col)`
  position: relative;

  @media ${({ theme }) => theme.Breakpoints.lg} {
    &::before {
      position: absolute;
      left: 0;
      display: block;
      content: '';
      background-color: ${({ theme }) => theme.Color.grey50};
      width: 1px;
      height: 110%;
      margin-right: ${({ theme }) => theme.Variables.gutter};
      transform: translateY(-2.5%);
    }
  }
`;

export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.Breakpoints.md} {
    width: 80%;
  }
  @media ${({ theme }) => theme.Breakpoints.lg} {
    width: 100%;
  }
`;

export const Li = styled.li`
  margin-top: ${({ theme }) => theme.Variables.gutter};
  flex: 100%;
  &:hover {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.Breakpoints.sm} {
    flex: 50%;
  }

  @media ${({ theme }) => theme.Breakpoints.lg} {
    flex: ${({ narrow }) => (narrow ? '50%' : '100%')};
  }
`;

export const LinkText = styled(Text)``;

export const Link = styled(GatsbyLink).attrs({
  activeClassName: 'active',
})`
  &.active {
    ${LinkText} {
      ${({ theme }) => theme.Typography.bold}
    }
  }
`;
