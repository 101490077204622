import React from 'react';
import { graphql } from 'gatsby';
import { PageBuilderContent } from '@Omegapoint/components/pageBuilderComponents';
import DefaultLayout from '@Layouts/Default';
import Breadcrumbs from '@components/Breadcrumbs';
import { Container, Row, Col } from '@Omegapoint/components/layout';
import PageBanner from '@components/PageBanner';
import { Text } from '@Omegapoint/components';
import { getTheme } from '@styles/global';

const StandardPage = (props) => {
  const { data, location, pageContext } = props;
  if (typeof data === 'undefined') return null;

  const { SiteSettings } = data.allUmbracoMarkets.edges
    .filter((edge) => {
      return (
        edge.node.Culture !== null &&
        edge.node.Culture.countryCode === pageContext.culture
      );
    })
    .map((page) => {
      return page.node.UmbracoContent.Settings;
    })[0];

  const theme = getTheme(SiteSettings?.SiteTheme.toLowerCase());

  return (
    <DefaultLayout
      culture={props.pageContext.culture}
      title={
        data?.cmsPages?.MetaTitle
          ? data?.cmsPages?.MetaTitle
          : data?.cmsPages?.PageTitle
      }
      secondaryNavigation={data.cmsPages.SecondaryNavigation}
      path={props.path}
      description={data?.cmsPages?.MetaDescription}
      requireLogin={data?.cmsPages?.RequireLogin}
    >
      {process.env.GATSBY_SHOW_PAGEBANNER !== 'false' && (
        <PageBanner
          links={
            data?.cmsPages?.SubMenu?.Show
              ? data?.cmsPages?.SubMenu?.Links
              : null
          }
        >
          {data?.cmsPages?.Name && (
            <Text
              color={theme.Color.white}
              variant="h2"
              component="h1"
              uppercase
            >
              {data?.cmsPages?.PageTitle
                ? data?.cmsPages?.PageTitle
                : data?.cmsPages?.Name}
            </Text>
          )}
          {data?.cmsPages?.MetaDescription && (
            <Text color={theme.Color.white} variant="p">
              {data?.cmsPages?.MetaDescription}
            </Text>
          )}
        </PageBanner>
      )}
      <Container gutter="30">
        <Row>
          <Col>
            <Breadcrumbs
              path={location.pathname}
              parts={data?.cmsPages?.Breadcrumb}
            />
          </Col>
        </Row>
      </Container>
      <PageBuilderContent
        content={data.cmsPages.Items.Content}
      ></PageBuilderContent>
    </DefaultLayout>
  );
};

export default StandardPage;

export const query = graphql`
  query StandardPageQuery($id: String!) {
    cmsPages(id: { eq: $id }) {
      Name
      PageTitle
      MetaTitle
      MetaDescription
      Url
      id
      Type
      SecondaryNavigation
      RequireLogin
      Breadcrumb
      SubMenu {
        Show
        Links {
          Cta {
            Url
            Text
          }
        }
      }
      Items {
        Content {
          ...PageBuilderContent
        }
      }
    }
    allUmbracoMarkets {
      edges {
        node {
          Culture {
            countryCode
          }
          UmbracoContent {
            Settings {
              SiteSettings {
                SiteTheme
              }
            }
          }
        }
      }
    }
  }
`;
